



const HowItWorksCards = ({cardImg, cardText, cardDescription}) => {
    return (
        <div className="HowItWorksCards flex flex-col gap-[20px] w-full max-w-[290px] ">
                <img src={cardImg} alt="cardImg" className="w-auto h-[245px] object-cover rounded-[35px] bg-gray-200"/>
                <h3 className="text-[24px] text-accent">{cardText}</h3>
                <p className="text-[18px] text-special-black">{cardDescription}</p>
        </div>
    );       
};


export default HowItWorksCards;