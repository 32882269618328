import { useState, useEffect } from 'react';
import wordsData from '../assets/russword/words_dictionary.json'; // Импортируйте ваш JSON файл

const Search = () => {
    const [value, setValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [firstPart, setFirstPart] = useState(''); // Для хранения первой части фразы

    // Преобразуем объект из JSON в массив слов
    const words = Object.keys(wordsData);

    // Функция фильтрации слов или словосочетаний
    const getSuggestions = (inputValue) => {
        const wordsArray = inputValue.trim().split(' '); // Разбиваем введенное значение на слова
        if (wordsArray.length > 1) {
            const firstWord = wordsArray.slice(0, wordsArray.length - 1).join(' '); // Первые слова
            const lastWord = wordsArray[wordsArray.length - 1].toLowerCase(); // Последнее введенное слово
            setFirstPart(firstWord); // Сохраняем первую часть фразы
            return lastWord.length === 0 ? [] : words.filter(word =>
                word.toLowerCase().startsWith(lastWord)
            ).map(word => `${firstWord} ${word}`).slice(0, 5); // Формируем словосочетание
        } else {
            const lastWord = wordsArray[0].toLowerCase(); // Если одно слово
            setFirstPart(''); // Сбрасываем первую часть
            return lastWord.length === 0 ? [] : words.filter(word =>
                word.toLowerCase().startsWith(lastWord)
            ).slice(0, 5); // Обычное автодополнение
        }
    };

    const handleChange = (e) => {
        const inputValue = e.target.value;
        setValue(inputValue);
        setSuggestions(getSuggestions(inputValue));
    };

    const handleSuggestionClick = (suggestion) => {
        // Устанавливаем выбранное слово как новое значение value
        setValue(suggestion); // Полностью заменяем значение на выбранное
        setSuggestions([]); // Очищаем предложения после выбора
    };

    return (
        <div className="HomePage__mainBannerSearch flex flex-row gap-[16px] w-full items-start h-[120px]">
            <div className="HomePage__mainBannerSearchField w-full flex flex-col gap-[10px] w-full">
                <input 
                    type="text" 
                    className='w-full rounded-[70px] px-[34px] py-[18px] bg-white' 
                    placeholder="Поиск" 
                    value={value} 
                    onChange={handleChange}
                />
                <div className="HomePage__mainBannerSearchNextWrds flex flex-row gap-[16px] flex-wrap">
                    {suggestions.map((suggestion, index) => (
                        <span 
                            key={index} 
                            onClick={() => handleSuggestionClick(suggestion)} // Заменяем значение при клике
                            className='py-[4px] px-[10px] rounded-[70px] bg-special-white font-special-black cursor-pointer'>
                            {suggestion}
                        </span>
                    ))}
                </div>
            </div>
            <button className="text-[16px] p-[18px] rounded-[70px] text-special-white bg-special-black">
                Найти
            </button>
        </div>
    )
};

export default Search;