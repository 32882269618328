import './App.css';
import Header from './components/Header';
import HomePage from './views/HomePage';
import AuthComponent from './views/HomePage/AuthComponent';

function App() {
  return (
    <div className="App">
      <Header/>
      <HomePage/>
      <AuthComponent/>
    </div>
  );
}

export default App;
