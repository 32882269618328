import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

const AuthComponent = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [userInfo, setUserInfo] = useState(null);

  const apiUrl = 'https://aidcar.ru/backend/wp-json';

  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      validateToken(token);
    }
  }, []);

  const validateToken = async (token) => {
    try {
      await axios.post(`${apiUrl}/jwt-auth/v1/token/validate`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      setIsAuthenticated(true);
      fetchUserInfo(token); // Запрос данных пользователя после проверки токена
    } catch (err) {
      console.error('Token validation failed:', err);
      Cookies.remove('token');
      setIsAuthenticated(false);
    }
  };

  const fetchUserInfo = async (token) => {
    try {
      const response = await axios.get(`${apiUrl}/wp/v2/users/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      setUserInfo(response.data);
    } catch (err) {
      console.error('Failed to fetch user info:', err);
    }
  };

  const handleRegister = async () => {
    try {
      const response = await axios.post(`${apiUrl}/custom/v1/register`, {
        username,
        password,
        email,
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response && response.data) {
        alert('Registration successful');
        setIsLogin(true);
      } else {
        setError('Unexpected API response structure');
      }
    } catch (err) {
      console.error('Registration error:', err);
      setError(err.response ? err.response.data.message : 'Registration failed');
    }
  };

  const handleLogin = async () => {
    try {
      const response = await axios.post(`${apiUrl}/jwt-auth/v1/token`, {
        username,
        password,
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      const { token } = response.data;
      Cookies.set('token', token, { expires: 7 });
      setIsAuthenticated(true);
      fetchUserInfo(token); // Загружаем информацию о пользователе при логине
      alert('Login successful');
    } catch (err) {
      console.error('Login error:', err);
      setError(err.response ? err.response.data.message : 'Login failed');
    }
  };

  const handleLogout = () => {
    Cookies.remove('token');
    setIsAuthenticated(false);
    setUserInfo(null);
    alert('Logout successful');
  };

  const toggleForm = () => {
    setIsLogin(!isLogin);
    setError(null);
  };

  return (
    <div>
      <h2>{isAuthenticated ? 'Welcome!' : (isLogin ? 'Login' : 'Register')}</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {!isAuthenticated && (
        <div>
          {isLogin ? (
            <div>
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button onClick={handleLogin}>Login</button>
              <p>
                Don't have an account? <button onClick={toggleForm}>Register</button>
              </p>
            </div>
          ) : (
            <div>
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button onClick={handleRegister}>Register</button>
              <p>
                Already have an account? <button onClick={toggleForm}>Login</button>
              </p>
            </div>
          )}
        </div>
      )}

      {isAuthenticated && userInfo && (
        <div>
          {console.log(userInfo)};
          <p>Welcome, {userInfo.name}!</p>
          <p>Email: {userInfo.email}</p>
          <button onClick={handleLogout}>Logout</button>
        </div>
      )}
    </div>
  );
};

export default AuthComponent;
