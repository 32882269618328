
import logo from '../assets/images/logo.svg';


const Header = () => {
    return (
        <div className="Header w-full absolute">
            <div className="Header__container flex w-full flex-row justify-between items-center px-[15px] py-[9px] mx-auto max-w-[1200px]">
                <div className="Header__logo w-[247px] flex flex-row items-center">
                    <span className='text-special-black text-[62px]'>AID</span>
                    <img src={logo} alt="logo" className='w-auto h-[49px]'/>
                </div>
                <div className="Header__nav flex flex-row items-center">
                    <nav>
                        <ul className='flex flex-row gap-[60px]'>
                            <li><a>Главная</a></li>
                            <li><a>Вариант</a></li>
                            <li><a>Партнерам</a></li>
                            <li><a>Карта</a></li>
                        </ul>
                    </nav>
                </div>
                <div className="Header__auth w-[247px] flex justify-between ">
                    <a href="#" className='bg-accent text-[16px] px-[19px] py-[15px] text-decoration-none rounded-[70px] text-special-white'>Регистрация</a>
                    <a href="#" className='bg-special-black text-[16px] px-[19px] py-[15px] rounded-[70px] text-special-white'>Войти</a>
                </div>
            </div>
        </div>
    );
}

export default Header;