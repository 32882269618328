import Search from "../../components/Search";
import bgImg from "../../assets/images/bgmainbanner.png";
import HowItWorksCards from "./HowItWorksCards";
import bgImg2 from "../../assets/images/Rectangle 227.png";
import bgImg3 from "../../assets/images/Rectangle 229.png";
const HomePage = () => {
    console.log(bgImg);
    
    return (
        <div 
        className="HomePage"
        >
            <div 
                className="HomePage__mainBannerContainer bg-cover bg-center" 
                style={{backgroundImage: `url(${bgImg})`}}
            >
                <div 
                    className='HomePage__mainBanner h-[587px] mx-auto max-w-[1200px] w-full flex flex-col justify-center gap-[40px]'
                >
                    <h1 
                        className="text-[64px] font-bold"
                    >
                        Текст придумать легко
                    </h1>
                    <Search/>
                </div>    
            </div>


            <div className="HomePage__howItWorksContainer mx-auto max-w-[1200px] w-full flex flex-col justify-start gap-[40px] mt-[41px]">
                <h2 className="text-[64px] text-special-black">Как все устроено</h2>
                <div className="HomePage__howItWorksCards flex flex-row justify-between flex-wrap">
                    <HowItWorksCards cardImg={""} cardText="Происк" cardDescription="Текст который описывает данный этап. Текст который описывает данный этап.Текст который описывает данный этап."/>
                    <HowItWorksCards cardImg={""} cardText="Что-то второе" cardDescription="Текст который описывает данный этап. Текст который описывает данный этап.Текст который описывает данный этап."/>
                    <HowItWorksCards cardImg={""} cardText="Что-то третье" cardDescription="Текст который описывает данный этап. Текст который описывает данный этап.Текст который описывает данный этап."/>
                </div>
            </div>

            <div className="HomePage__regTextContainer mx-auto max-w-[1200px] w-full flex flex-col justify-center gap-[16px] mt-[155px]">
                <div className="flex flex-row gap-[20px] items-center">
                    <img className="rounded-[35px] w-[440px]" src={bgImg2} />
                    <div className="w-full flex flex-col gap[20px] ">
                        <h3 className="text-[48px] font-bold">Текст призывающий к регистрации</h3>
                        <p>Небольшой текст который показывает преимущество или просто ценность продукта. Небольшой текст который показывает преимущество или просто ценность продукта.Небольшой текст который показывает преимущество или просто ценность продукта.</p>
                    </div>
                </div>
                <div className="flex flex-row gap-[20px] items-center">
                    <img src={bgImg3} className="rountended-[35px] h-[110px] w-[440px]" />
                    <div className="w-full rounded-[35px] flex flex-col items-center justify-center h-[110px] bg-[#9747FF15] ">
                        <span className="text-[36px]">Решить проблему сейчас...</span>
                    </div>
                </div>
            </div>    



        </div>
    )
};


export default HomePage;